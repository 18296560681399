#image-editor {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 100vh;
    overflow: hidden;
    min-width: 600px;
    width: 100%;
}

#image-editor img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
}

#dragbox-container {
    position: absolute;
}

#newbox {
    border: 2px solid rgb(255, 255, 255);
    background: rgba(255, 255, 255, .1);
    position: absolute;
    box-sizing: border-box;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.5);
    pointer-events: none;
}

#box-context-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 2;
    background-color: #fff;
    color: #151625;
}

.context-menu-item {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    cursor: pointer;
}
