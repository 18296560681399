html, body {
  margin: 0;
  font-size: 14px;
  color: #fff;
  background-color: #151625;
  font-family: "Lexend", sans-serif;
}

h2 {
  font-size: 1.42rem;
}

h3 {
  font-size: 1.28rem;
}