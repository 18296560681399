#image-redactor-menu {
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    max-width: 300px;
    min-width: 300px;
    height: calc(100vh - 60px);
    background-color: #272940;
}

#menu-button-container {
    margin-top: auto;
    display: flex;
    flex-direction: column;
}

/* Offset on first element of menu to make room for our Logo */
.option-group:first-of-type{
    margin-top: 100px;
}

.option-group {
    margin: 25px 0;
}

.option-box {
    display: flex;
    padding: 30px;
    gap: 20px;
    flex-direction: column;
    border: 2px solid #3b3e5d;
    border-radius: 10px;
}

.option-box label {
    font-weight: 600;
    display: flex;
    flex-direction: column;
}

.option-box select, .option-box input {
    font-size: 1.14rem;
    color: #fff;
    margin: 10px 0 10px -4px;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

/* Currently just being used for toggle options */
.option-box div {
    display: flex;
    justify-content: space-between;
}

.option-box .toggle-button {
    padding: 0;
}

.option-box .toggle-button .material-icons {
    font-size: 1.2rem;
}

.loadingSpinner {
    width: 100%;
    margin: 0;
    opacity: .8;
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(-360deg);
    }
}
