#image-redactor-app{
    display: flex;
}

#sighthound-logo {
    position: absolute;
    top: 40px;
    left: 50px;
    width: 200px;
    height: auto;
}

button {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 1.14rem;
    cursor: pointer;
    background: none;
    border: none;
    transition: .3s;
    font-family: "Lexend", sans-serif;
}

.btn-full-width {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.btn-primary {
    height: 60px;
    padding: 20px 40px;
    background-color: #5062d4;
    border-radius: 50px;
}

.btn-disabled {
    opacity: .7;
    pointer-events: none;
}

.btn-text {
    display: flex;
    margin: 40px;
    padding: 0;
}

.btn-text:hover {
    color: #d1d6eb;
}

.btn-primary:hover {
    background-color: #5f70de;
}
