#image-upload {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#image-upload div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    cursor: pointer;
}

#image-upload .material-icons {
    font-size: 4rem;
}

#image-upload h3 {
    margin: 10px;
}
